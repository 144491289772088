import './BlockTotal.scss';
import { Component } from 'react';
import autobind from 'autobind-decorator';
import globalStore from 'modules/global-store';
import { route } from 'modules/route';
import * as session from 'modules/session';
import Scroll from 'react-scroll';
import { withTranslation } from 'react-i18next';
import productHelper from 'modules/helpers/product-helper';
import {
  getDataPurchaseToAnalytics,
  getDataPurchaseToGUA,
  getFbPixelDataToPurchase,
} from 'frontend/analytics/analyticsHelper';
import { analytics } from 'frontend/analytics/analytics';
import { withRouter } from 'react-router';
import Button from 'src/shared/ui/Button/Button';
import { withSelectedCountry, withCurrency } from 'src/entities/countries';
import cn from 'classnames';
import { connect } from 'react-redux';
import { withBreakpoints } from 'frontend/hocs';
import {
  DeliveryCheckNotify,
  FreeDeliveryWarningCheckout,
} from 'src/entities/delivery';
import styles from './blockTotal.module.scss';
import Emitter from '../../Emitters';
import { createOrderApi } from './api/orderApi';

const handleAnalytics = ({
  payment_type,
  delivery_type,
  order,
  hash,
  countryCurrency,
  cartProducts,
  cart,
}) => {
  analytics.orderingActions({
    paymentType: payment_type,
    deliveryType: delivery_type,
    deliveryDate: order.delivery_date,
    id: hash,
  });
  // TODO combine to one func 'purchase'
  analytics.ecom(
    'purchase',
    getDataPurchaseToAnalytics({
      id: hash,
      deliveryPrice: order.delivery_price,
      deliveryType: delivery_type,
    }),
    countryCurrency
  );
  analytics.gUAEcom(
    getDataPurchaseToGUA({
      id: hash,
      deliveryPrice: order.delivery_price,
      countryCurrency,
      cartProducts,
      cart,
    })
  );
  analytics.fbPixel(
    'Purchase',
    getFbPixelDataToPurchase({ countryCurrency, cart })
  );
};

const getDeliveryPriceByCard = (delivery) => {
  const { cost_with_card = 0, cost_without_card = 0 } = delivery || {};

  const { phone_bonuses } = session.get('cart');

  const has_card = phone_bonuses?.has_card;

  return has_card ? cost_with_card : cost_without_card;
};

// @withRouter
// @connect(
//   ({
//     complectation: {
//       free: freeComplectation,
//       availiable: avaliableComplectation,
//     },
//     delivery: { address: selectedFullAddress, delivery = {} },
//   }) => ({
//     freeComplectation,
//     avaliableComplectation,
//     selectedFullAddress,
//     delivery,
//   })
// )
class BlockTotal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOrderInProcess: false,
      agree_1: true,
      agree_2: true,
      agree_3: true,
      agree_4: true,
      isButtonDisabled: true,
    };
  }

  componentDidMount() {
    Emitter.addListener('FORM_IS_VALID', this.confirmValidation);
    Emitter.addListener('CHECKOUT', this.submitForm);
    Emitter.addListener('CART_CHANGE', this.enableButton);
    this.enableButton();
  }

  componentWillUnmount() {
    Emitter.removeListener('FORM_IS_VALID', this.confirmValidation);
    Emitter.removeListener('CHECKOUT', this.submitForm);
    Emitter.removeListener('CART_CHANGE', this.enableButton);
  }

  @autobind
  handleButton() {
    if (this.props.choosePayMethod) {
      this.props.choosePayMethod();
    }
  }

  getUserGifts = () => {
    const userGifts = session.get('userGifts', []);
    if (!userGifts || !Array.isArray(userGifts) || !userGifts.length) {
      return [];
    }
    return userGifts
      .map((item) => {
        return { product_id: item.product_id, qty: item.qty };
      })
      .filter((item) => item.qty > 0);
  };

  getStore(form) {
    const { stores } = this.props;
    if (form && stores) {
      return stores.filter((store) => +store.id === +form.store_id)[0];
    }
  }

  specialItemInCart = () => {
    let bool = false;
    const { items = [] } = session.get('cart', {});
    items.forEach(({ product_id }) => {
      const { tags = [] } = productHelper.get(product_id) || {};
      if (tags.find((item) => item.tag_id === 200)) {
        bool = true;
      }
    });
    return bool;
  };

  // @autobind
  createOrder = async (order, delivery_type = '') => {
    const { payment_type, isOrderInProcess } = this.state;
    const {
      t,
      dispatch,
      avaliableComplectation,
      freeComplectation,
      countryCurrency,
    } = this.props;
    if (isOrderInProcess) {
      return;
    }
    this.setState({
      isOrderInProcess: true,
    });

    const complectations = avaliableComplectation.map(({ id, qty }) => {
      const totalQty = freeComplectation[id] ? freeComplectation[id].qty : qty;
      return { product_id: id, qty: totalQty };
    });
    // add options for create full address on node
    const orderWithOptions = {
      ...order,
      complectations,
      options: {
        city: t('BlockTotal.order_city'),
        street: t('BlockTotal.order_street'),
        house: t('BlockTotal.order_house'),
        building: t('BlockTotal.order_building'),
        entrance: t('BlockTotal.order_entrance'),
        floor: t('BlockTotal.order_floor'),
        apartment: t('BlockTotal.order_apartment'),
      },
    };

    const { data, error } = await dispatch(
      createOrderApi.endpoints.createOrder.initiate(orderWithOptions)
    );
    if (error) {
      const { type, data: errorData } = error;
      if (type === 'products') {
        Emitter.emit('MODAL_CALL', {
          modal: 'StopListsModal',
          value: { product_ids: errorData },
        });
      }
      if (type === 'message') {
        Emitter.emit('MODAL_CALL', {
          modal: 'ErrorCartModal',
          value: t(`BlockTotal.${errorData}`),
        });
      }
      this.setState({
        isOrderInProcess: false,
      });
      return;
    }
    const { salt, hash } = data.order;

    handleAnalytics({
      payment_type,
      delivery_type,
      order,
      hash,
      countryCurrency,
      cartProducts: globalStore.get('cart_products'),
      cart: session.get('cart'),
    });

    Emitter.emit('MODAL_CALL', { modal: false });
    this.props.history.push(route(`/order/${hash}/${salt}/`));
  };

  @autobind
  toOrderPickup(form) {
    const {
      phone,
      email,
      name,
      comment,
      cutlery_qty,
      store_id,
      select_date,
      select_time,
    } = form;

    const { geodata } = session.get('cart');
    const user_gifts = this.getUserGifts();
    const order = {
      phone,
      email,
      name,
      payment_type: 'ch',
      delivery_date: `${select_date} ${select_time}`.trim(),
      cutlery_qty,
      comment,
      store_id,
      user_gifts,
    };

    if (geodata) {
      order.latitude = geodata.latitude || null;
      order.longitude = geodata.longitude || null;
    }
    this.createOrder(order, 'pickup');
  }

  @autobind
  enableButton() {
    this.setState({ isButtonDisabled: false });
  }

  @autobind
  canChoosePayMethod() {
    const { isButtonDisabled } = this.state;
    const { total, isPickup } = this.props;
    const { delivery: { order_min = 0 } = {} } = globalStore.take('city_info');
    const isMinAmount = total < order_min;

    return !isButtonDisabled && this.isAllAgree() && (isPickup || !isMinAmount);
  }

  @autobind
  confirmPayment(payment_type) {
    const { isOrderInProcess } = this.state;
    if (!this.isAllAgree() || isOrderInProcess) {
      return;
    }

    if (payment_type === 'ps') {
      const { form } = this.state;
      this.toOrderPickup(form);
      return;
    }

    const { total } = this.props;
    /*     const { delivery: { delivery_price = 0 } = {} } = this.state; */
    const { delivery } = this.props;
    const delivery_price = getDeliveryPriceByCard(delivery);
    Emitter.emit('MODAL_CALL', {
      modal: 'PaymentModal',
      value: { total: +total + delivery_price, payment_type },
    });
    this.setState({ payment_type });
  }

  @autobind
  isAllAgree() {
    const { agree_1, agree_2, agree_3, agree_4 } = this.state;
    return agree_1 && agree_2 && agree_3 && agree_4;
  }

  @autobind
  confirmValidation({ form }) {
    this.enableButton();
    this.setState({ form });
    this.scrollPage();
  }

  @autobind
  scrollPage() {
    const { form } = this.state;
    const { isPickup } = this.props;

    const isValidForm = !!form;
    if (isValidForm && !isPickup) {
      const { block } = this;
      const boundTop = block.getBoundingClientRect().top;
      const scrollTop = window.pageYOffset;

      Scroll.animateScroll.scrollTo(boundTop + scrollTop - 100, {
        duration: 300,
        delay: 100,
      });
    }
  }

  @autobind
  async submitForm(change_from = '') {
    this.enableButton();
    const { geodata } = session.get('cart');
    const {
      form: {
        phone,
        email,
        comment,
        building,
        entrance,
        floor,
        apartment,
        name,
        select_time,
        select_date,
        cutlery_qty,
        store_id,
        address,
      },
      payment_type = 'ch',
    } = this.state;

    const current_city = globalStore.get('current_city');
    const { selectedFullAddress } = this.props;
    const { settings } = current_city;
    // TODO: заменить на useMapDisabled
    const isMapDisabled = !!settings.map_disabled;
    const totalAddress = isMapDisabled
      ? { street: address || '' }
      : selectedFullAddress;

    const { city, street, house, district } = totalAddress || {};
    const { delivery = {} } = this.props;
    const delivery_price = getDeliveryPriceByCard(delivery);
    const user_gifts = this.getUserGifts();
    const order = {
      phone,
      email,
      comment,
      city,
      house,
      building,
      entrance,
      floor,
      district: district || '',
      apartment,
      street,
      name,
      payment_type,
      delivery_date: `${select_date} ${select_time}`.trim(),
      cutlery_qty,
      store_id,
      change_from,
      user_gifts,
      delivery_price,
    };
    if (geodata) {
      order.latitude = geodata.latitude || null;
      order.longitude = geodata.longitude || null;

      this.createOrder(order, 'delivery');
    } else {
      this.createOrder(order, 'pickup');
    }
  }

  render() {
    const { agree_1, agree_2, agree_3, agree_4, form, isOrderInProcess } =
      this.state;
    const {
      total,
      isPickup,
      total_for_delivery,
      isTablet,
      isMobile,
      t,
      delivery,
      currency,
      isUkraine,
    } = this.props;
    const {
      cash: isCash,
      card_online: isCardOnline,
      card_courier: isCardCourier,
      store_id,
      zero_min_order: freeOrderCost,
    } = isPickup ? {} : delivery || {};

    const current_city = globalStore.get('current_city');
    const { delivery_discount, phone_bonuses } = session.get('cart');
    const hasCard = phone_bonuses ? phone_bonuses.has_card : false;
    const { emailgate = null } = current_city;
    const { delivery: { order_min = 0 } = {} } = globalStore.take('city_info');
    const canChoosePayMethod = this.canChoosePayMethod();
    const isDiscount = total < total_for_delivery;
    const isValidForm = !!form;
    const isDeliveryDiscount = !!delivery_discount;
    const isAllAgree = this.isAllAgree();
    const isMinAmount = total < order_min;
    const showMinAmountWarning =
      !canChoosePayMethod && isMinAmount && !isPickup;
    const store = this.getStore(form);

    const freeDeliveryLacks = freeOrderCost ? freeOrderCost - total : null;

    const delivery_price = getDeliveryPriceByCard(delivery);
    return (
      <div className="block-total" ref={(block) => (this.block = block)}>
        {isValidForm && !isPickup && !isMinAmount ? (
          <div className={styles.deliveryInfoContainer}>
            {(isTablet || isMobile) && (
              <DeliveryCheckNotify hasCard={hasCard} />
            )}
            <div className={styles.totalRow}>
              <p>{t('BlockTotal.order_amount')}</p>
              <p>
                {total}&nbsp;{currency}
              </p>
            </div>
            <div className={styles.totalRow}>
              <div className={styles.deliveryTitleContainer}>
                <p className={styles.deliveryInfoParagraph}>
                  {t('BlockTotal.delivery')}
                </p>
                {!isTablet && !isMobile && (
                  <DeliveryCheckNotify hasCard={hasCard} />
                )}
              </div>
              {emailgate || !store_id ? (
                <p className={styles.deliveryInfoParagraph}>
                  {t('BlockTotal.delivery_fr')}
                </p>
              ) : (
                <p className={styles.deliveryInfoParagraph}>
                  {delivery_price}&nbsp;{currency}
                </p>
              )}
            </div>
            {isDeliveryDiscount ? (
              <div className="block-total__total-row">
                <p>{t('BlockTotal.a_discount_on_shipping')}</p>
                <p>
                  {delivery_discount} {currency}
                </p>
              </div>
            ) : undefined}
            {freeDeliveryLacks && freeDeliveryLacks > 0 && (
              <FreeDeliveryWarningCheckout
                currency={currency}
                lacks={freeDeliveryLacks}
              />
            )}
            <div className="block-total__total-row total">
              <p>{t('BlockTotal.total')}</p>
              <p>
                {total + delivery_discount + delivery_price} {currency}
              </p>
            </div>
          </div>
        ) : (
          <div className="wrapper">
            <div className="block-total__total-row">
              <p>{t('BlockTotal.total')}</p>
              <p>
                {total} {currency}
                {isDiscount ? (
                  <small>
                    {total_for_delivery} {currency}
                  </small>
                ) : undefined}
              </p>
            </div>
          </div>
        )}
        <div
          className="block-total__compliance-row"
          onClick={() => {
            this.setState({ agree_1: !agree_1 });
          }}
        >
          <div
            className={cn('sw-checkbox', {
              ua: isUkraine,
              checked: agree_1,
            })}
          >
            <input type="checkbox" name="agree_1" />
          </div>
          <p>
            {t('BlockTotal.By_making_an_order_for')}
            <a href={route('/')}>{t('BlockTotal.site')}</a>
            &nbsp;
            {t('BlockTotal.i_confirm')}&nbsp;
            <a href={route('/page/rules/')}>{t('BlockTotal.address')}</a>
            ,&nbsp;
            {t('BlockTotal.and_confirm')}
          </p>
        </div>
        <div
          className="block-total__compliance-row"
          onClick={() => {
            this.setState({ agree_2: !agree_2 });
          }}
        >
          <div
            className={cn('sw-checkbox', {
              ua: isUkraine,
              checked: agree_2,
            })}
          >
            <input type="checkbox" name="agree_2" />
          </div>
          <p>
            {t('BlockTotal.By_making_an_order_for')}
            <a href={route('/')}>{t('BlockTotal.site')}</a>
            &nbsp;
            {t('BlockTotal.iAgreeToTheCollection')}
            <a href={route('/page/terms/')}>{t('BlockTotal.privacy')}</a>.
          </p>
        </div>
        <div
          className="block-total__compliance-row"
          onClick={() => {
            this.setState({ agree_3: !agree_3 });
          }}
        >
          <div
            className={cn('sw-checkbox', {
              ua: isUkraine,
              checked: agree_3,
            })}
          >
            <input type="checkbox" name="agree_3" />
          </div>
          <p>
            {t('BlockTotal.By_making_an_order_for')}
            <a href={route('/')}>{t('BlockTotal.site')}</a>
            &nbsp;
            {t('BlockTotal.i_give_my_consent')}
          </p>
        </div>
        {this.specialItemInCart() ? (
          <div
            className="block-total__compliance-row"
            onClick={() => {
              this.setState({ agree_4: !agree_4 });
            }}
          >
            <div
              className={cn('sw-checkbox', {
                ua: isUkraine,
                checked: agree_4,
              })}
            >
              <input type="checkbox" name="agree_4" />
            </div>
            <p>
              {t('BlockTotal.By_making_an_order')}&nbsp;
              {t('BlockTotal.iConfirmBuy')}&nbsp;
              <a
                href={route(
                  '/akcii/polnye-usloviya-akcii-dr-sv-sekretnyj-tovar/?utm_source=allergen'
                )}
              >
                {t('BlockTotal.alergicProducts')}
              </a>
              .
            </p>
          </div>
        ) : undefined}
        <div className="block-total__btn-container">
          {isValidForm && (isPickup || !isMinAmount) ? (
            <div className="block-total__select-payment-container">
              <div className="block-total__title">
                <h2>{t('BlockTotal.select_a_payment_method')}</h2>
              </div>
              <div className="block-total__btns-container">
                {!isPickup ? (
                  isCash && (
                    <button
                      type="button"
                      className={`payment-btn ${
                        isOrderInProcess || !isAllAgree ? 'disable' : ''
                      }`}
                      onClick={() => {
                        this.confirmPayment('ch');
                      }}
                    >
                      <span className="icon-cash" />
                      {t('BlockTotal.cash_to_the_courier')}
                    </button>
                  )
                ) : (
                  <button
                    type="button"
                    className={`payment-btn ${
                      isOrderInProcess || !isAllAgree ? 'disable' : ''
                    }`}
                    onClick={() => {
                      this.confirmPayment('ps');
                    }}
                  >
                    <span className="icon-cash" />
                    {t('BlockTotal.payment_in_the_store')}
                  </button>
                )}

                {isCardOnline || (store && store.has_online_payment) ? (
                  <button
                    type="button"
                    className={`payment-btn ${
                      isOrderInProcess || !isAllAgree ? 'disable' : ''
                    }`}
                    onClick={() => {
                      this.confirmPayment('co');
                    }}
                  >
                    <span className="icon-card-online" />
                    {t('BlockTotal.card_online')}
                  </button>
                ) : undefined}
                {isCardCourier ? (
                  <button
                    type="button"
                    className={`payment-btn ${
                      isOrderInProcess || !isAllAgree ? 'disable' : ''
                    }`}
                    onClick={() => {
                      this.confirmPayment('cc');
                    }}
                  >
                    <span className="icon-card-courier" />
                    {t('BlockTotal.card_to_a_courier')}
                  </button>
                ) : undefined}
              </div>
            </div>
          ) : (
            <div className="wrapper">
              {showMinAmountWarning ? (
                <div className="page-cart__warning min-order-message">
                  {`${t('BlockTotal.cart_warning')} ${order_min} ${currency}`}
                </div>
              ) : undefined}
              <Button
                disabled={!canChoosePayMethod}
                onClick={this.handleButton}
              >
                {t('BlockTotal.select_a_payment_method')}
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ countries, complectation, delivery }) => ({
  countryCurrency: countries.country.currency,
  freeComplectation: complectation.free,
  avaliableComplectation: complectation.availiable,
  selectedFullAddress: delivery.address,
  delivery: delivery.delivery,
});

export default withRouter(
  connect(mapStateToProps)(
    withTranslation()(
      withBreakpoints(withCurrency(withSelectedCountry(BlockTotal)))
    )
  )
);
