import './MainMobile.scss';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import Promotions from 'frontend/Components/Main/TrioOnMainPage/TrioOnMainPage';
import MainSlider from 'frontend/Components/Main/MainSlider/MainSlider';
import CustomText from 'frontend/Components/CustomText/CustomText';
import { PromoBlocks } from '../promo/ui/promo-blocks/promo-blocks';
import { Publication } from '../publication';

function MainMobile(props) {
  const { t } = useTranslation();
  const renderNewsSlider = () => {
    const normalizeNews = props.news.map((item) => {
      return {
        ...item,
        publication_date: item.publication_from,
      };
    });
    return (
      <Publication
        list={normalizeNews}
        titleText={t('Main.news')}
        bottomLinkText={t('Main.all_news')}
        href="news"
      />
    );
  };
  const renderMainSlider = () => {
    const { banner } = props;
    if (!banner.length) return null;
    return (
      <div className="main-slider">
        <MainSlider t={t} banner={banner} />
      </div>
    );
  };

  const renderStocks = () => {
    const { stocks } = props;
    if (!stocks || !stocks.length) return null;
    return <Promotions stocks={stocks} />;
  };

  const renderPromoBlocks = () => {
    const { promoLinks } = props;
    return <PromoBlocks promoLinks={promoLinks} />;
  };

  const { mainpage } = props;
  const activeBlocks = mainpage.filter((block) => block.active);

  const activeBlocksKeys = {
    slider: renderMainSlider(),
    stocks: renderStocks(),
    promos: renderPromoBlocks(),
    news: renderNewsSlider(),
  };

  return (
    <div className="page-main">
      {activeBlocks.map(({ name }) => {
        if (!name) return null;
        return <Fragment key={name}>{activeBlocksKeys[name]}</Fragment>;
      })}
      <CustomText />
    </div>
  );
}

export default MainMobile;
