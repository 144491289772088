import { Component } from 'react';
import ssr from 'modules/decorators/ssr';
import api from 'modules/helpers/api';
import globalStore from 'modules/global-store';
import { storeHelper } from 'src/entities/stores';
import { Breadcrumbs } from 'shared/ui/breadcrumbs';
import { PageContainer } from 'shared/ui/page-container';
import ReviewsAddModal from '../../../Components/Reviews/AddModal/AddModal';
import StoresPageContent from './page-content/StoresPageContent';
import CustomText from '../../../Components/CustomText/CustomText';
import CustomHelmet from '../../../Components/CustomHelmet';

class Stores extends Component {
  static LOADED_KEY = 'stores_loaded';

  static initialData = (fetch, params, globalStore) => {
    return fetch('store.get_list_city')
      .then((stores) =>
        globalStore.set('stores', storeHelper.flatMetro(stores))
      )
      .catch((e) => {
        console.error('Stores', e);
        return [];
      });
  };

  constructor(props) {
    super(props);
    this.state = {
      stores: globalStore.get('stores', []),
    };
  }

  componentDidMount() {
    if (globalStore.get(Stores.LOADED_KEY)) {
      globalStore.unlink(Stores.LOADED_KEY);
    } else {
      Stores.initialData(api, {}, globalStore)
        .then(() => {
          this.setState({
            stores: globalStore.get('stores', []),
          });
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }

  getTitle() {
    const { city_name = '' } = globalStore.get('current_city');
    const { t } = this.props;
    return `${t('localization.Stores')} ${t(
      'localization.companyName'
    )} ${city_name}`;
  }

  render() {
    const { stores } = this.state;
    const { t } = this.props;

    return (
      <PageContainer>
        <CustomHelmet title={this.getTitle()} />
        <Breadcrumbs
          links={[
            {
              text: t('localization.Stores'),
            },
          ]}
        />
        <StoresPageContent stores={stores} />
        <CustomText />
        <ReviewsAddModal city_stores={stores} />
      </PageContainer>
    );
  }
}

export default ssr(Stores);
