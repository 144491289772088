import { LinkTabs } from 'shared/ui/link-tabs';
import { DeliveryCityAvg } from 'src/entities/delivery';
import { useTranslation } from 'react-i18next';
import { DeliveryCheck } from 'features/delivery-check';
import CustomText from 'src/frontend/Components/CustomText/CustomText';
import ErrorComponent from 'src/frontend/Components/ErrorComponent/ErrorComponent';
import { Breadcrumbs } from 'shared/ui/breadcrumbs';
import CustomHelmet from 'src/frontend/Components/CustomHelmet';
import { useCurrency } from 'src/entities/countries';
import { DeliveryMap } from 'features/delivery-map';
import { Suggest } from 'features/address-suggest';
import { Media } from 'shared/lib/media';
import { useSelectCity, useSelectCityInfo } from 'src/entities/cities';
import { PageContainer } from 'shared/ui/page-container';
import { DeliveryHeader } from './delivery-header';
import styles from './delivery.module.scss';

function Delivery() {
  const { t } = useTranslation();
  const tabsItems = [
    {
      url: `/delivery`,
      title: t('localization.Delivery'),
    },
    {
      url: `/addresses`,
      title: t('localization.Stores'),
    },
  ];
  const { is_delivery } = useSelectCity();
  const cityInfo = useSelectCityInfo();
  const currency = useCurrency();

  const { delivery, delivery_card: deliveryCard } = cityInfo;
  const deliveryAvgProps = {
    priceFrom: deliveryCard.delivery_price,
    priceTo: delivery.delivery_price,
    orderMin: delivery.order_min,
    timeFrom: delivery.from ?? '-',
    timeTo: delivery.to ?? '-',
    currency,
  };

  if (!is_delivery) {
    return <ErrorComponent />;
  }

  return (
    <PageContainer>
      <CustomHelmet title={t('localization.Delivery')} />
      <Breadcrumbs
        links={[
          {
            text: t('localization.Delivery'),
          },
        ]}
      />
      <div className={styles.pageDeliveryBlock}>
        <div className={styles.pageDeliverySearch}>
          <DeliveryHeader />
          <DeliveryCityAvg {...deliveryAvgProps} />
          <div className={styles.address}>
            <div className={styles.deliveryCheck}>
              <Media greaterThanOrEqual="notebook">
                <span className={styles.addressTitle}>
                  {t('Delivery.your_address')}
                </span>
              </Media>
              <DeliveryCheck
                suggest={
                  <Suggest
                    placeholder={t('localization.Enter street or subway')}
                  />
                }
              />
            </div>
          </div>
        </div>
        <div className={styles.deliveryRoutes}>
          <LinkTabs items={tabsItems} />
        </div>
      </div>
      <div className={styles.deliveryMap}>
        <DeliveryMap />
      </div>
      <CustomText />
    </PageContainer>
  );
}

export default Delivery;
