/* eslint-disable react/no-unused-state */
import { Component } from 'react';
import autobind from 'autobind-decorator';
import api from 'modules/helpers/api';
import globalStore from 'modules/global-store';
import './OrderInfo.scss';
import { withTranslation } from 'react-i18next';
import cn from 'classnames';
import { formatDateWithLocale } from 'shared/lib/date';
import { LinkButton } from 'shared/ui/link-button';
import { StorePhoneLink } from 'src/entities/stores';
import CallCenterInfo from 'src/shared/ui/call-center-info/CallCenterInfo';
import { OrderInfoComplectation } from 'src/entities/complectation';
import { withCurrency } from 'src/entities/countries';
import { PageTitle } from 'shared/ui/page-title';
import Banner from '../Banner/Banner';

const orderStatuses = {
  received: 1,
  kitchen: 2,
  expects: 3,
  delivery: 4,
  done: 5,
  canceled: 6,
};

const paymentStatus = {
  1: 'payment_status_new',
  2: 'payment_status_incomplete',
  3: 'payment_status_approved',
  4: 'payment_status_complete',
  5: 'payment_status_failed',
  7: 'payment_status_processed',
};

const paymentType = {
  ch: 'payment_cash',
  cc: 'payment_card_courier',
  co: 'payment_card_online',
};

const isComplectationItem = (item) => {
  return (
    !item.parent_id && item.price === 0 && !item.is_gift && !item.is_user_gift
  );
};

class OrderInfo extends Component {
  timeOut = 10; // секунды

  constructor(props) {
    super(props);
    const { order } = props;
    const [cartItems, complectation] = order.items.reduce(
      (acc, item) => {
        if (isComplectationItem(item)) {
          acc[1].push({ id: item.id, title: item.title, qty: item.qty });
          return acc;
        }
        acc[0].push(item);
        return acc;
      },
      [[], []]
    );
    this.state = {
      order,
      items: cartItems,
      complectation,
    };
  }

  componentDidMount() {
    this.checkStatus();
    const {
      order: { total, total_dlv },
    } = this.state;

    api('banner.thx').then((bannerInfoThx) => {
      const { condition_value: bannerDisplayCost = 0, display_condition = '' } =
        bannerInfoThx || {};

      if (!Array.isArray(bannerInfoThx)) {
        this.setVisibilityBanner({
          total,
          total_dlv,
          bannerDisplayCost,
          display_condition,
        });
      }
      this.setState({ bannerInfoThx });
    });
  }

  componentWillUnmount() {
    clearInterval(this.orderInfoUpdateInterval);
  }

  @autobind
  getStatus() {
    const { order = {} } = this.state;
    const { order_salt } = this.props;
    const { hash: order_hash } = order;
    api('order.status', { order_hash, order_salt })
      .then((status) => {
        if (!status) {
          console.error('Check status failed');
          return;
        }
        this.setState({
          order: Object.assign(order, status),
        });

        this.checkStatus();
      })
      .catch(() => {
        this.checkStatus();
      });
  }

  setVisibilityBanner({
    total,
    total_dlv,
    bannerDisplayCost,
    display_condition,
  }) {
    const bannerVisibility =
      !display_condition ||
      (display_condition === 'order_sum' &&
        total + total_dlv >= bannerDisplayCost);
    this.setState({ bannerVisibility });
  }

  cpaMarkup = (cpaPixel) => {
    return { __html: cpaPixel };
  };

  @autobind
  checkStatus() {
    const {
      order,
      order: { is_pickup, status_id },
    } = this.state;

    if (order) {
      if (is_pickup) {
        if (
          status_id === orderStatuses.expects ||
          status_id === orderStatuses.canceled
        ) {
          return;
        }
      } else if (
        status_id === orderStatuses.done ||
        status_id === orderStatuses.canceled
      ) {
        return;
      }
      clearInterval(this.orderInfoUpdateInterval);
      this.orderInfoUpdateInterval = setInterval(
        this.getStatus,
        this.timeOut * 1000
      );
    }
  }

  renderPaymentStatus() {
    const { order } = this.state;

    const { t } = this.props;
    if (order.payment_status) {
      return (
        <div className="order-info__options">
          <div className="order-info__option">
            {t('OrderInfo.payment_status')}:
          </div>
          <div className="order-info__value">
            {t(`OrderInfo.${paymentStatus[order.payment_status]}`)}
          </div>
        </div>
      );
    }
    return '';
  }

  renderItems() {
    const { items } = this.state;

    const products = items.filter((item) => !item.parent_id);

    return (
      <div className="order-info__products">
        {products.map((item, index) => {
          return (
            <div key={`order-info-product-${index}`}>
              {this.renderItem(item, index)}
            </div>
          );
        })}
      </div>
    );
  }

  @autobind
  renderToppingsNew(data) {
    const { currency } = this.props;
    return (
      <div className="order-info__toppings_new">
        {data.map((elem, index) => {
          return (
            <div
              className="order-info__options order-info__topping_new"
              key={`${index}-topping`}
            >
              <div className="order-info__topping_new-name">{`- ${elem.title}`}</div>
              <div className="order-info__topping_new-price">
                {elem.qty}{' '}
                {elem.price !== 0 ? `x ${`${elem.price} ${currency}`}` : ''}{' '}
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  renderItem(item, index) {
    const { items } = this.state;
    const { currency } = this.props;
    // const { items } = order;
    const itemId = item.id;

    const toppings = items.filter((elem) => {
      return !!elem.parent_id;
    });

    const currentToppings = toppings.filter((topping) => {
      return +topping.parent_id === +itemId;
    });

    if (item.product_id === 1139) return;

    if (typeof item.price === 'undefined') {
      return (
        <div key={`item-${index}-orders`}>
          <div className="order-info__options order-info__options--item">
            <div className="order-info__option order-info__option--item">
              {item.title}
            </div>
            <div className="order-info__value order-info__value--item">
              x
              <div>
                {item.qty} {currency}
              </div>
            </div>
          </div>
          {currentToppings.length
            ? this.renderToppingsNew(currentToppings)
            : undefined}
        </div>
      );
    }

    return (
      <div key={`item-${index}`}>
        <div className="order-info__options order-info__options--item">
          <div className="order-info__option order-info__option--item">
            {item.title}
          </div>
          <div className="order-info__value order-info__value--item">
            {item.qty}{' '}
            {item.price !== 0 || (!item.price && item.discount_rule_id)
              ? `x ${`${item.price} ${currency}`}`
              : ''}
          </div>
        </div>
        {currentToppings.length
          ? this.renderToppingsNew(currentToppings)
          : undefined}
      </div>
    );
  }

  renderAddress() {
    const { order } = this.state;
    const {
      t,
      i18n: { language },
    } = this.props;
    const shortYear = t('locale.year');

    if (order.is_pickup) {
      return [
        <div
          className="order-info__options address"
          key="order-info__options-1"
        >
          <div className="order-info__option">{t('OrderInfo.pickup')}:</div>
          <div className="order-info__value">{order.store_address}</div>
        </div>,
        order.delivery_date && (
          <div className="order-info__options" key="order-info__options-2">
            <div className="order-info__option">{t('OrderInfo.date')}</div>
            <div className="order-info__value">
              {formatDateWithLocale(
                order.delivery_date,
                `d MMMM yyyy '${shortYear}'. HH:mm`,
                language
              )}
            </div>
          </div>
        ),
      ];
    }
    // TODO: заменить на useMapDisabled
    const current_city = globalStore.get('current_city');
    const { settings } = current_city;

    const isMapDisabled = !!settings.map_disabled;

    return [
      <div className="order-info__options address" key="order-info-options-3">
        <div className="order-info__option">
          {t('OrderInfo.order_address')}:
        </div>
        {isMapDisabled ? (
          <div className="order-info__value">{order.address.street}</div>
        ) : (
          <div className="order-info__value">
            {t('OrderInfo.city')} {order.address.city}, {order.address.street}
            {order.address.house
              ? `, ${t('OrderInfo.house')}. ${order.address.house}`
              : ''}
            {order.address.building
              ? `, ${t('OrderInfo.building')} ${order.address.building}`
              : ''}
            {order.address.entrance
              ? `, ${
                  current_city.text_id === 'spb'
                    ? t('OrderInfo.front_door')
                    : t('OrderInfo.entrance')
                } ${order.address.entrance}`
              : ''}
            {order.address.apartment
              ? `, ${t('OrderInfo.apartment')}. ${order.address.apartment}`
              : ''}
          </div>
        )}
      </div>,
      order.delivery_date ? (
        <div className="order-info__options" key="order-info__options-4">
          <div className="order-info__option">
            {t('OrderInfo.date_delivery')}:
          </div>
          <div className="order-info__value">
            {formatDateWithLocale(
              order.delivery_date,
              `d MMMM yyyy '${shortYear}'. HH:mm`,
              language
            )}
          </div>
        </div>
      ) : undefined,
    ];
  }

  renderStatus = () => {
    const { order: { status_id, is_pickup = false } = {} } = this.state;
    const isExpect = status_id === orderStatuses.expects;
    const { t } = this.props;

    const statusContainer = cn('status__container', {
      'for-pickup': is_pickup,
    });

    const excludeArray = [orderStatuses.expects, orderStatuses.canceled];
    const statusArray = Object.entries(orderStatuses).filter(
      (item) => !excludeArray.includes(item[1])
    );

    return (
      <div className={statusContainer}>
        {statusArray.map((item, index) => {
          const isActive =
            (index === 1 && isExpect) ||
            status_id === item[1] ||
            (is_pickup && index === 2 && isExpect);
          const isDone =
            (!isExpect && status_id > item[1]) ||
            status_id === orderStatuses.done ||
            (isExpect && index < 2 && is_pickup);

          const statusItem = cn('status__item', {
            active: isActive,
            done: isDone,
          });
          const statusCircles = cn('status__circles', {
            active:
              status_id >= item[1] ||
              (is_pickup && (isDone || (index === 2 && isExpect))),
          });
          return (
            <div className={statusItem} key={item}>
              {index !== 0 ? (
                <div className={statusCircles}>
                  <span />
                  <span />
                </div>
              ) : undefined}
              <div className="status__content">
                <div className="status__icon" />
                <span>
                  {is_pickup && (index === 2 || index === 3)
                    ? t(`OrderInfo.pickup_done_${index}`)
                    : t(`OrderInfo.${item[0]}`)}
                </span>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  render() {
    const {
      order = {},
      bannerInfoThx,
      bannerVisibility,
      complectation,
    } = this.state || {};
    const {
      link,
      location = 1,
      image: bannerImgDesk,
      mobile_image: bannerImgMobile,
    } = bannerInfoThx || {};

    const {
      phone,
      text_id,
      emailgate = null,
      order_info = 'no_info',
    } = globalStore.get('current_city');

    const { t, currency } = this.props;
    const { status_id, store_id } = order;
    const isActiveOrder = status_id !== orderStatuses.canceled;

    const infoContainer = cn('order-info__container', {
      'order-info__container_reverse': location === 2,
    });
    const thanx = cn('order-info__thanx', {
      'canceled-info': !isActiveOrder,
    });

    return (
      <div className="order-info">
        <div className={infoContainer}>
          {bannerVisibility && link && (
            <section className="order-info__banner">
              <Banner {...{ bannerImgDesk, bannerImgMobile, link }} />
            </section>
          )}

          <section className="order-info__content">
            <PageTitle className="order-info__title">
              {t('OrderInfo.active_order')}
            </PageTitle>
            {order.cpa_pixel && (
              <div dangerouslySetInnerHTML={this.cpaMarkup(order.cpa_pixel)} />
            )}
            <div>
              <div className="order-info__success">
                <div className={thanx}>
                  {isActiveOrder
                    ? `${t('OrderInfo.thanks')}!`
                    : t('OrderInfo.order_canceled')}
                </div>
                {isActiveOrder ? (
                  <div>
                    {this.renderStatus()}
                    {order_info === 'no_info' ? (
                      <div className="order-info__call">
                        {t('OrderInfo.call to your no_info')}
                      </div>
                    ) : (
                      <div className="order-info__call">
                        {t(`OrderInfo.call to your ${order_info}`)}{' '}
                        {order.phone}{' '}
                        {t(`OrderInfo.call to your ${order_info}_2`)}{' '}
                      </div>
                    )}
                    {order.status === 'received' &&
                      (phone ? (
                        <CallCenterInfo phone={phone} />
                      ) : (
                        <StorePhoneLink cityId={text_id} storeId={store_id} />
                      ))}
                  </div>
                ) : undefined}
              </div>
              <h3 className="order-info__description">
                {`${t('OrderInfo.order_title')} № ${order.hash} ${t(
                  'OrderInfo.ordered_phone'
                )}: ${order.phone}  `}
              </h3>
              <div className="order-info__row">
                <div className="order-info__column order-info__column--address">
                  <div className="order-info__options name">
                    <div className="order-info__option">
                      {t('OrderInfo.order_name')}:
                    </div>
                    <div className="order-info__value">{order.name}</div>
                  </div>
                  <div className="order-info__options email">
                    <div className="order-info__option">E-mail:</div>
                    <div className="order-info__value">
                      {order.email ? order.email : '-'}
                    </div>
                  </div>
                  {this.renderAddress()}
                  <div className="order-info__options order_title">
                    <div className="order-info__option order_title">
                      {t('OrderInfo.order_title')}:
                    </div>
                    <div className="order-info__value order_title">
                      {order.hash}
                    </div>
                  </div>
                  {!order.is_pickup ? (
                    <div className="order-info__options payment_type">
                      <div className="order-info__option">
                        {t('OrderInfo.payment_type')}:
                      </div>
                      <div className="order-info__value">
                        {t(`OrderInfo.${paymentType[order.payment_type]}`)}
                      </div>
                    </div>
                  ) : undefined}

                  {this.renderPaymentStatus()}
                </div>
                <div className="order-info__column">
                  {this.renderItems()}
                  <OrderInfoComplectation
                    items={complectation}
                    currency={currency}
                  />
                  <div className="order-info__options">
                    <div className="order-info__option">
                      {t('OrderInfo.order_total')}:
                    </div>
                    <div className="order-info__value">
                      {order.total} {currency}
                    </div>
                  </div>
                  {!order.is_pickup ? (
                    <div className="order-info__options">
                      <div className="order-info__option">
                        {t('OrderInfo.delivery_total')}:
                      </div>
                      <div className="order-info__value">
                        {emailgate || !store_id
                          ? t('OrderInfo.delivery_fr')
                          : `${order.total_dlv} ${currency}`}
                      </div>
                    </div>
                  ) : undefined}
                  <div className="order-info__options">
                    <div className="order-info__option">
                      {t('OrderInfo.totalPrice')}:
                    </div>
                    <div className="order-info__value">
                      {order.total + order.total_dlv} {currency}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="order-info__link-container">
              <LinkButton href="/menu" className="order-info__link">
                {t('OrderInfo.back_to_menu')}
              </LinkButton>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withCurrency(OrderInfo));
