import { ReactNode } from 'react';
import ballonImage from 'assets/images/icon/ballon-icon.svg';
import uaLocationIcon from 'assets/images/icon/uaLocationIcon.svg';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import { useAppSelector } from 'shared/lib/store';
import {
  deliverySliceSelectors,
  useDeliveryCheckQuery,
} from 'src/entities/delivery';
import Button from 'src/shared/ui/Button/Button';
import { useSelectedCountry } from 'src/entities/countries';
import { useCityAndLang } from 'shared/lib/use-city-and-lang';
import styles from './deliveryCheck.module.scss';

function DeliveryCheck({ suggest }: { suggest: ReactNode }) {
  const { t } = useTranslation();

  const { isUkraine } = useSelectedCountry();

  const { fullAddress } = useAppSelector(
    deliverySliceSelectors.selectFullAddress
  );

  const cityLang = useCityAndLang();

  const deliveryCheckParams = fullAddress
    ? {
        latitude: fullAddress.latitude,
        longitude: fullAddress.longitude,
        ...cityLang,
      }
    : null;

  const { refetch, isUninitialized } = useDeliveryCheckQuery(
    deliveryCheckParams ?? skipToken,
    { refetchOnMountOrArgChange: true }
  );

  const onDeliveryCheckButton = () => {
    if (!isUninitialized) refetch();
  };

  const LocatioIcon = isUkraine ? uaLocationIcon : ballonImage;
  return (
    <div className={styles.deliveryCheck}>
      <div className={styles.toolbar}>
        <div className={styles.leftToolbar}>
          <div className={styles.geolocationWrap}>
            <LocatioIcon />
          </div>
          {suggest}
        </div>
        <div className={styles.checkbuttonContainer}>
          <Button className={styles.swButton} onClick={onDeliveryCheckButton}>
            {t('localization.Check')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default DeliveryCheck;
